.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.width-100 {
  width: 100%;
}
.btn-orange {
  background: #EC4A0A;
  color: #ffffff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border-width: 0;
  width: 100%;
}
.text-orange {
  color: #EC4A0A;
}

.close {
  float: right;
  text-decoration: unset;
}

.btn-social {
  padding: 0;
  border: 0;
  width: 44px;
  background: none;
  img {
    width: 100%;
  }
  &:hover {
    background: none;
  }
}

.group-btn {
  display: flex;
  justify-content: flex-end;
}

.mb-0 {
  margin-bottom: 0;
}

.btn-link {
  text-decoration: none;
}

.line-center {
  position: relative;
  &:after, &:before{
    background-color: #D0D5DD;
    position: absolute;
    content: '';
    height: 1px;
    top: 50%;
    left: 0;
    width: calc(50% - 32px);
  }
  &:before {
    right: 0;
    left: unset;
  }
}

.background-orange {
  background-color: #FFFAF5;
}

.btn-purchase {
  background: #EC4A0A;
  border: 0;
  color: #ffffff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  width: 88px;
}

.btn-logout {
  border: 0;
  background: #ffffff;
}

.icon-register {
  height: 44px;
  background: #ffffff;
  color: green;
  border: 1px solid #D0D5DD;
  box-shadow: rgba(16, 24, 40, 0.05);
}

.btn-redirect-login {
  background: #EC4A0A;
  box-shadow: rgba(16, 24, 40, 0.05);
  border: 0;
  width: 88px;
  height: 40px;
}

.custom-input {
  border: 1px solid #EAECF0;
  border-radius: 8px;
  background: #ffffff;

  input {
    border: 0;
  }
}

.btn-delete-account {
  background: #ffffff;
  color: #667085;
  border: 0;
  padding: 0;

  &:hover, &:focus {
    background: #ffffff;
    color: #667085;
    border: 0;
  }
}

.body {
  background: #FFFAF5;
  min-height: 100vh !important;
}

.btn-notification {
  padding: 0;
  background: #FFFAF5;
  border: 0;
  color: #EC4A0A;
  &:hover {
    background: #FFFAF5;
    color: #EC4A0A;
  }
  &::before {
    display: none !important;
  }
}

.btn-notification-action {
  border: none;
  padding: 0;
  background: inherit;
  &::before {
    display: none !important;
  }
}

.list-notification {
  &:hover {
    background: #F2F4F7;

    .notification-action {
      display: flex !important;
      justify-content: flex-end;
      align-items: start;
    }
  }

  &:active {
    background: #F2F4F7;

    .notification-action {
      display: flex !important;
      justify-content: flex-end;
      align-items: start;
    }
  }
}

.card-input-number {
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;  // for firefox
}

.purchase-duration {
  width: fit-content;
  background: #ffffff;
  border-radius: 30px;
  padding: 10px !important;

  button {
    background: inherit;
    border: 0;
    color: #667085;

    &.active {
      background: #FB6514 !important;
      color: #ffffff !important;
      border-radius: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.purchase-size {
  background: #ffffff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.purchase-free-plan {
  background: linear-gradient(180deg, #FFFFFF 0%, #F2F4F7 100%);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.purchase-basic-plan {
  background: linear-gradient(180deg, #98A2B3 0%, #667085 100%);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.purchase-standard-plan {
  background: linear-gradient(180deg, #2E90FA 0%, #175CD3 100%);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.purchase-premium-plan {
  background: linear-gradient(180deg, #FD853A 0%, #EC4A0A 100%);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.purchase-plan:hover {
  .show-purchase-btn {
    display: block !important;
  }
}

.btn-close-purchase-expiration {
  border: 0;
  padding: 3px;
  //height: 30px;
}

.min-vh-90 {
  min-height: 90vh !important;
  padding-top: 50px;
}

.btn-change-username {
  background: #EC4A0A;
  color: #ffffff;
  border: 0;
}

.btn-cancel {
  background: #8a93a2;
  border: 0;
}

.long-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-pagination {
  background: #EC4A0A;
  color: #ffffff;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;

  &:hover, &:focus {
    background: #EC4A0A;
    color: #ffffff;
  }
}


@media all and (max-width: 991px) {
  .show-purchase-btn {
    display: block !important;
  }
}

@media all and (min-width: 992px) {
  .purchase-plan:hover {
    .show-purchase-btn {
      display: block !important;
    }
  }

  .show-purchase-btn {
    display: none !important;
  }
}

.text-small {
  font-size: 15px;
}
